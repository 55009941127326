import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsRadioButtonComponent } from './fs-radio-button.component';

@NgModule({
  declarations: [FsRadioButtonComponent],
  imports: [CommonModule],
  exports: [FsRadioButtonComponent],
})
export class FsRadioButtonModule {}
