import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, ValidationStatus } from './services/AuthService';

/**
 * Prevent access to routes if access-token is not present.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // return this.authService
    //   .isTokenValid()
    //   .then((res: boolean) => {
    //     if (res) {
    //       this.authService.validationResult = ValidationStatus.Valid;
    //       return true;
    //     } else {
    //       this._router.navigate(['login']).then(() => {
    //         this.authService.validationResult = ValidationStatus.Invalid;
    //       });
    //       return false;
    //     }
    //   })
    //   .catch(() => {
    //     this._router.navigate(['']).then(() => {
    //       this.authService.validationResult = ValidationStatus.Invalid;
    //     });
    //     return false;
    //   });
    return this.authService.isTokenValid().then((validation: boolean) => {
      if (validation) {
        return true;
      } else {
        this._router.navigate(['login']).then(() => {
          this.authService.validationResult = ValidationStatus.Invalid;
        });
        return false;
      }
    });
  }
}
