<div *ngIf="showLoginPage" class="main-content flex-center">
    <form *ngIf="authPageMode === 1" [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <mat-card>
            <img mat-card-image src="../../../assets/images/auth-image1.jpg" alt="Full Swing">
            <mat-card-content>
                <h3>Sign in with email and password</h3>
                <p *ngIf="showError" class="error-auth">Incorrect username or password.</p>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input required matInput type="email" [formControl]="email">
                    <button *ngIf="email.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="helperService.onClear($event, email)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input #passwordTB required matInput type="password" [formControl]="password">
                    <button *ngIf="password.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="onChangeVisibility(passwordTB)">
                        <mat-icon *ngIf="passwordTB.type === 'password'">visibility_off</mat-icon>
                        <mat-icon *ngIf="passwordTB.type === 'text'">visibility</mat-icon>
                    </button>
                </mat-form-field>
                <a (click)="authPageMode = 2; showError = false">Forgot your password ?</a>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button type="submit" class="prm-btn loader-btn" [disabled]="!loginForm.valid || loader">
                    <div class="flex-center full-width">
                        <mat-spinner [diameter]="20" *ngIf="loader"></mat-spinner>
                        <span>Sign in</span>
                    </div>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>

    <form *ngIf="authPageMode === 2" [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPassword()">
        <mat-card>
            <img mat-card-image src="../../../assets/images/auth-image.jpg" alt="Full Swing">
            <mat-card-content>
                <h3>Forgot your password?</h3>
                <p> Enter your Email below and we will send a message to reset your password </p>
                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input required matInput type="email" [formControl]="emailF">
                    <button *ngIf="emailF.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="helperService.onClear($event, emailF)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <a (click)="authPageMode = 1; showError = false">Go back to sign in</a>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button type="submit" class="prm-btn loader-btn"
                    [disabled]="!forgotPasswordForm.valid || loader">
                    <div class="flex-center full-width">
                        <mat-spinner [diameter]="20" *ngIf="loader"></mat-spinner>
                        <span>Reset my password</span>
                    </div>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>

    <form *ngIf="authPageMode === 3" [formGroup]="passwordResetForm" (ngSubmit)="onResetPassword()">
        <mat-card>
            <img mat-card-image src="../../../assets/images/auth-image.jpg" alt="Full Swing">
            <mat-card-content>
                <p>We have sent a password reset code to your email. Enter it below to
                    reset your password.</p>
                <p *ngIf="showErrorResetPassword" class="error-auth">Reset password failed.</p>
                <mat-form-field *ngIf="!showCompletePassword" appearance="fill">
                    <mat-label>Code</mat-label>
                    <input required matInput type="text" [formControl]="codeR">
                    <button *ngIf="codeR.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="helperService.onClear($event, codeR)">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>New Password</mat-label>
                    <input #passwordTBNew required matInput type="password" [formControl]="newPasswordR"
                        (keyup)="checkValidations()">
                    <button *ngIf="newPasswordR.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="onChangeVisibility(passwordTBNew)">
                        <mat-icon *ngIf="passwordTBNew.type === 'password'">visibility_off</mat-icon>
                        <mat-icon *ngIf="passwordTBNew.type === 'text'">visibility</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Enter New Password Again</mat-label>
                    <input #passwordTBAgain required matInput type="password" [formControl]="againPasswordR"
                        (keyup)="isMatchPasswords = againPasswordR.value === newPasswordR.value">
                    <button *ngIf="againPasswordR.value" matSuffix mat-icon-button aria-label="Clear" type="button"
                        (click)="onChangeVisibility(passwordTBAgain)">
                        <mat-icon *ngIf="passwordTBAgain.type === 'password'">visibility_off</mat-icon>
                        <mat-icon *ngIf="passwordTBAgain.type === 'text'">visibility</mat-icon>
                    </button>
                </mat-form-field>
                <ul>
                    <li [style.color]="isPasswordLawerCase ? 'green' : 'red'">
                        <mat-icon *ngIf="!isPasswordLawerCase">close</mat-icon>
                        <mat-icon *ngIf="isPasswordLawerCase">check</mat-icon>Password must contain a lower case letter
                    </li>
                    <li [style.color]="isPasswordUpperCase ? 'green' : 'red'">
                        <mat-icon *ngIf="!isPasswordUpperCase">close</mat-icon>
                        <mat-icon *ngIf="isPasswordUpperCase">check</mat-icon>Password must contain an upper case letter
                    </li>
                    <li [style.color]="isPasswordHasSpecialCharacter ? 'green' : 'red'">
                        <mat-icon *ngIf="!isPasswordHasSpecialCharacter">close</mat-icon>
                        <mat-icon *ngIf="isPasswordHasSpecialCharacter">check</mat-icon>Password must contain a special
                        character
                    </li>
                    <li [style.color]="isPasswordHasNumber ? 'green' : 'red'">
                        <mat-icon *ngIf="!isPasswordHasNumber">close</mat-icon>
                        <mat-icon *ngIf="isPasswordHasNumber">check</mat-icon>Password must contain a number
                    </li>
                    <li [style.color]="isPasswordUpTo8Characters ? 'green' : 'red'">
                        <mat-icon *ngIf="!isPasswordUpTo8Characters">close</mat-icon>
                        <mat-icon *ngIf="isPasswordUpTo8Characters">check</mat-icon>Password must contain at least 8
                        characters
                    </li>
                    <li [style.color]="isMatchPasswords ? 'green' : 'red'">
                        <mat-icon *ngIf="!isMatchPasswords">close</mat-icon>
                        <mat-icon *ngIf="isMatchPasswords">check</mat-icon>Passwords must match
                    </li>
                </ul>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button type="submit" class="prm-btn loader-btn"
                    [disabled]="resetPasswordResultValidation() || loader">
                    <div class="flex-center full-width">
                        <mat-spinner [diameter]="20" *ngIf="loader"></mat-spinner>
                        <span>Change password</span>
                    </div>
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>