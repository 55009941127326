import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AppVersionModal, ElementValueModel } from 'src/app/shared/models/Application.model';
import { AppConfigService } from 'src/app/shared/services/app-config.service';
import { MODAL_DATA } from 'src/app/shared/staticObjects';

@Component({
  selector: 'app-version-modal',
  templateUrl: './version-modal.component.html',
  styleUrls: ['./version-modal.component.scss'],
})
export class VersionModalComponent implements OnInit {
  modalData: AppVersionModal[];
  modalIcons: ElementValueModel[];
  logo: ElementValueModel;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    public dialogRef: MatDialogRef<VersionModalComponent>,
    public appConfService: AppConfigService
  ) {
    this.modalIcons = new Array<ElementValueModel>();
    this.logo = new ElementValueModel({});
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.modalData = MODAL_DATA;
    }, 100);
  }

  ngOnInit(): void {
    this.modalIcons = this.appConfService.get()?.elements?.modal?.icons;
    this.logo = this.appConfService.get()?.elements?.logo;
    
    this.accordion?.closeAll();
  }
}
