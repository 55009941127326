import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config: Object;
  public configSubject$: Subject<Object> = new Subject<Object>();

  constructor() {}

  public async load(): Promise<any> {
    try {
      const response = await fetch(environment.appConfigPath, {
        method: 'GET',
      });
      const config = await response.json();
      this.config = config;
      this.configSubject$.next(this.config);
    } catch (err) {
      console.error(err);
    }
  }

  public get(key: string = null): any {
    if (!key || !key.length) return this.config;
    return this.config[key];
  }
}
