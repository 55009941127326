import { NavigationBarItem } from 'src/app/shared/models/Application.model';

export const NavItems: NavigationBarItem[] = [
  {
    id: 1,
    name: 'dashboard',
    icon: `<svg width=\"30\" height=\"30\" viewBox=\"0 0 30 30\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M7.5 7.5H0V0H7.5V7.5ZM18.75 0H11.25V7.5H18.75V0ZM30 0H22.5V7.5H30V0ZM7.5 11.25H0V18.75H7.5V11.25ZM18.75 11.25H11.25V18.75H18.75V11.25ZM30 11.25H22.5V18.75H30V11.25ZM7.5 22.5H0V30H7.5V22.5ZM18.75 22.5H11.25V30H18.75V22.5ZM30 22.5H22.5V30H30V22.5Z\" fill=\"white\"/></svg>`,
    route: 'dashboard',
    urlParam: '',
    expandable: false,
    level: 1,
    activate: false,
    // children: [
    //   {
    //     id: 5,
    //     name: 'child 1',
    //     icon: 'dashboard',
    //     route: 'home',
    //     expandable: false,
    //     level: 1,
    //     activate: false,
    //   },
    //   {
    //     id: 6,
    //     name: 'child 2',
    //     icon: 'dashboard',
    //     route: 'sdv',
    //     expandable: false,
    //     level: 1,
    //     activate: false,
    //   },
    // ],
  },
  {
    id: 2,
    name: 'commercial',
    icon: `<svg width=\"36\" height=\"32\" viewBox=\"0 0 36 32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M18 7.11111V0H0V32H36V7.11111H18ZM14.4 28.4444H3.6V24.8889H14.4V28.4444ZM14.4 21.3333H3.6V17.7778H14.4V21.3333ZM14.4 14.2222H3.6V10.6667H14.4V14.2222ZM14.4 7.11111H3.6V3.55556H14.4V7.11111ZM32.4 28.4444H18V10.6667H32.4V28.4444ZM28.8 14.2222H21.6V17.7778H28.8V14.2222ZM28.8 21.3333H21.6V24.8889H28.8V21.3333Z\" fill=\"white\"/></svg>`,
    route: 'account',
    urlParam: 'commercial',
    expandable: false,
    level: 1,
    activate: false,
  },
  {
    id: 3,
    name: 'residential',
    icon: `<svg width=\"38\" height=\"36\" viewBox=\"0 0 38 36\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M18.9999 23.9311C20.2855 23.9311 21.5422 23.5519 22.6111 22.8414C23.68 22.131 24.5131 21.1212 25.0051 19.9398C25.4971 18.7584 25.6258 17.4584 25.375 16.2042C25.1242 14.95 24.5051 13.798 23.5961 12.8938C22.687 11.9895 21.5288 11.3738 20.268 11.1243C19.0071 10.8748 17.7002 11.0029 16.5124 11.4922C15.3247 11.9816 14.3096 12.8103 13.5953 13.8735C12.8811 14.9368 12.4999 16.1868 12.4999 17.4656C12.4999 19.1803 13.1847 20.8249 14.4037 22.0374C15.6227 23.2499 17.276 23.9311 18.9999 23.9311ZM18.9999 26.3018C14.6665 26.3018 5.99988 28.4677 5.99988 32.7673V36H31.9999V32.7673C31.9999 28.4677 23.3386 26.3018 18.9999 26.3018Z\" fill=\"white\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.9999 0.179688L37.0139 10.7762L34.9858 14.2239L18.9999 4.82041L3.01392 14.2239L0.98584 10.7762L18.9999 0.179688Z\" fill=\"white\"/></svg>`,
    route: 'account',
    urlParam: 'residential',
    expandable: false,
    level: 1,
    activate: false,
  },
  {
    id: 4,
    name: 'cognito-users',
    icon: `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1203_34777)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.85 10H35.6C37.745 10 39.5 11.8 39.5 14V36C39.5 38.2 37.745 40 35.6 40H4.4C2.255 40 0.5 38.2 0.5 36V14C0.5 11.8 2.255 10 4.4 10H14.15V4C14.15 1.8 15.905 0 18.05 0H21.95C24.095 0 25.85 1.8 25.85 4V10Z" fill="white"/>
<path d="M24.85 10V11H25.85H35.6C37.169 11 38.5 12.3283 38.5 14V36C38.5 37.6717 37.169 39 35.6 39H4.4C2.831 39 1.5 37.6717 1.5 36V14C1.5 12.3283 2.831 11 4.4 11H14.15H15.15V10V4C15.15 2.32827 16.481 1 18.05 1H21.95C23.519 1 24.85 2.32827 24.85 4V10Z" stroke="#858585" stroke-opacity="0.8" stroke-width="2"/>
<path d="M18.3045 12.3914H21.6788V4.3479H18.3045V12.3914Z" fill="#575961"/>
<path d="M26.0706 28.8044H36.1088V25.8696H26.0706V28.8044Z" fill="#575961"/>
<path d="M26.0706 22.9349H36.1088V20.0001H26.0706V22.9349Z" fill="#575961"/>
<path d="M18.8556 20.9783C18.8556 23.6808 16.7499 25.8696 14.1501 25.8696C11.5504 25.8696 9.44469 23.6808 9.44469 20.9783C9.44469 18.2759 11.5504 16.087 14.1501 16.087C16.7499 16.087 18.8556 18.2759 18.8556 20.9783Z" fill="#575961"/>
<path d="M4.73926 33.2066C4.73926 29.9539 11.0093 28.3153 14.1501 28.3153C17.291 28.3153 23.561 29.9539 23.561 33.2066V35.6522H4.73926V33.2066Z" fill="#575961"/>
</g>
<defs>
<clipPath id="clip0_1203_34777">
<rect x="0.5" width="39" height="40" rx="4" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    route: 'cognito-users',
    urlParam: '',
    expandable: false,
    level: 1,
    activate: false,
  },
  // {
  //   id: 3,
  //   name: 'account',
  //   icon: `<svg width=\"36\" height=\"32\" viewBox=\"0 0 36 32\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M18 7.11111V0H0V32H36V7.11111H18ZM14.4 28.4444H3.6V24.8889H14.4V28.4444ZM14.4 21.3333H3.6V17.7778H14.4V21.3333ZM14.4 14.2222H3.6V10.6667H14.4V14.2222ZM14.4 7.11111H3.6V3.55556H14.4V7.11111ZM32.4 28.4444H18V10.6667H32.4V28.4444ZM28.8 14.2222H21.6V17.7778H28.8V14.2222ZM28.8 21.3333H21.6V24.8889H28.8V21.3333Z\" fill=\"white\"/></svg>`,
  //   route: 'companies',
  //   expandable: false,
  //   level: 1,
  //   activate: false,
  // },
  // {
  //   id: 4,
  //   name: 'users',
  //   icon: `<svg width=\"38\" height=\"36\" viewBox=\"0 0 38 36\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M18.9999 23.9311C20.2855 23.9311 21.5422 23.5519 22.6111 22.8414C23.68 22.131 24.5131 21.1212 25.0051 19.9398C25.4971 18.7584 25.6258 17.4584 25.375 16.2042C25.1242 14.95 24.5051 13.798 23.5961 12.8938C22.687 11.9895 21.5288 11.3738 20.268 11.1243C19.0071 10.8748 17.7002 11.0029 16.5124 11.4922C15.3247 11.9816 14.3096 12.8103 13.5953 13.8735C12.8811 14.9368 12.4999 16.1868 12.4999 17.4656C12.4999 19.1803 13.1847 20.8249 14.4037 22.0374C15.6227 23.2499 17.276 23.9311 18.9999 23.9311ZM18.9999 26.3018C14.6665 26.3018 5.99988 28.4677 5.99988 32.7673V36H31.9999V32.7673C31.9999 28.4677 23.3386 26.3018 18.9999 26.3018Z\" fill=\"white\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.9999 0.179688L37.0139 10.7762L34.9858 14.2239L18.9999 4.82041L3.01392 14.2239L0.98584 10.7762L18.9999 0.179688Z\" fill=\"white\"/></svg>`,
  //   route: 'users',
  //   expandable: false,
  //   level: 1,
  //   activate: false,
  // },
];
