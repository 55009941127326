<main class="main-div" [ngStyle]="{'overflow': authService.validationResult === 1 ? 'auto' : 'hidden'}">
    
    <div *ngIf="authService.validationResult !== 1 && showStartPage" class="start-content">
        <div class="flex-center splash-container">
            <div class="login-content">
                <div class="logo-bar"></div>
                <button mat-raised-button type="button" class="prm-btn fs-btn-txt loader-btn" id="sendlogin"
                    [disabled]="loader" (click)="checkAuthentication()">
                    <span>Start</span>
                </button>
            </div>
        </div>
    </div>

    <ng-container>
        <div *ngIf="authService.validationResult === 1">
            <nav-bar></nav-bar>
        </div>
        <div class="router-container" *ngIf="authService.validationResult !== 2"
            [ngClass]="authService.validationResult === 1 ? 'content-wrapper' : ''">
            <router-outlet></router-outlet>
        </div>
    </ng-container>
</main>

<mini-loader></mini-loader>