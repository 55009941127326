<nav class="side-nav flex-between flex-col">
    <div class="flex-start flex-col side-nav-wrapper">
        <ul *ngIf="!navLoader">
            <li class="flex-center">
                <button class="baseline toggle-menu" type="button" mat-icon-button (click)="toggleNavRoute()"></button>
                <div *ngIf="navbarIcons && navbarIcons.length" class="flex-center"
                    [innerHtml]="navbarIcons[3]?.value | safeHtml"></div>
            </li>
            <hr style="width: 70%;">
            <li *ngFor="let item of navItems" class="flex-center" [id]="'sn-' + item.name"
                [class]="item.activate ? 'side-nav-active' : 'side-nav-dis'" [attr.data-nav-route]="item.route">
                <button mat-icon-button type="button" (click)="changeRoute(item.route, item.urlParam, item.name)">
                </button>
                <div class="flex-center" [innerHTML]="sanitizer.bypassSecurityTrustHtml(item.icon)"></div>
            </li>
        </ul>
    </div>
    <div style="width: 100%;" class="flex-end flex-col">
        <ul *ngIf="navbarIcons && navbarIcons.length">
            <li id="sn-exit">
                <button mat-icon-button type="button" (click)="onLogoutClick()">
                    <span class="flex-center" [innerHtml]="navbarIcons[1].value | safeHtml"></span>
                </button>
            </li>
            <li style="height: fit-content;">
                <hr style="width: 86%;">
            </li>
            <li id="sn-exit">
                <button mat-icon-button type="button" (click)="onOpenVerModal()">
                    <span class="flex-center" [innerHtml]="navbarIcons[2].value | safeHtml"></span>
                </button>
            </li>
        </ul>
    </div>
</nav>

<div class="top-nav">
    <div class="row">
        <div class="flex-center col-4">
            <breadcrumb style="width: 100%;"></breadcrumb>
        </div>
        <div class="flex-center col-4">
            <!--<img class="center-logo" src="../../../assets/images/FSG-H-Logo-31H.png" alt="">-->
        </div>
        <div class="flex-end col-4">
            <i [innerHtml]="logo?.value | safeHtml"></i>
        </div>
    </div>
</div>

<div #navRoutes class="nav-routes flex-between flex-col">
    <mat-tree *ngIf="!navLoader; else tempDiv" [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding [class]="node.activate ? 'nav-route-active' : ''">
            <button type="button" *ngIf="node.children && node.children.length" mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <button type="button" mat-button [attr.data-nav-route]="node.route" class="route-button"
                (click)="changeRoute(node.route, node.urlParam, node.name)">
                <span>{{ node.name.toUpperCase() }}</span>
            </button>
        </mat-tree-node>
    </mat-tree>
    <ng-template #tempDiv>
        <div></div>
    </ng-template>

    <ul>
        <li>
            <button type="button" id="rn-exit" mat-button (click)="onLogoutClick()">
                <span>EXIT</span>
            </button>
        </li>
        <li>
            <hr style="width: 70%;">
            <a class="ver-no" (click)="onOpenVerModal()">
                <span style="margin-inline-start: 8px;">Changelog</span>
                <span class="no-chips">{{ modalObjects[0].title }}</span>
            </a>
        </li>
    </ul>
</div>
<div *ngIf="navRouteToggle" class="nav-mask" (click)="toggleNavRoute()"></div>