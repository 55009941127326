import { Component, OnInit } from '@angular/core';
import { BreadCrumbService } from './breadcrumb.service';
import { Location } from '@angular/common';

@Component({
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  constructor(public bcService: BreadCrumbService, public location: Location) {}

  ngOnInit(): void {
    // this.location.onUrlChange((url, state) => {
    //   console.log(url);
    //   console.log(state);
    // })
    // .subscribe((next) => {
    //   console.log(next);
    //   if (next && next.pop) {
    //     this.bcService.removeBreadCrumb();
    //   }
    // });
  }

  // getFullRoute(): string {
  //   let fullRoute: string = '';
  //   let routeArr = this.bcService.getBreadCrumbs;
  //   for (let index = 0; index < routeArr.length; index++) {
  //     fullRoute += routeArr[index].route;
  //   }
  //   return fullRoute;
  // }

  // checkLastRoute(index: number): boolean {
  //   // if (route && route.length) {
  //   //   let index = this.bcService.getBreadCrumbs.findIndex(
  //   //     (f) => f.route === route
  //   //   );
  //   //   return index === this.bcService.getBreadCrumbs.length - 1 ? false : true;
  //   // }
  //   // return true;
  //   if (index === 0 || index === 1) return false;

  //   let length = this.bcService.getBreadCrumbs.length;

  //   if (length - 1 === index) return false;

  //   return true;
  // }
}
