<div class="dialog-main-content">
    <div class="full-width flex-start flex-column modal-main-div">
        <div class="header-modal-content">
            <div class="modal-icon"></div>
            <h1 mat-dialog-title><i [innerHtml]="logo?.value | safeHtml"></i></h1>
            <button type="button" mat-button class="close-btn" (click)="dialogRef.close()">
                <i [innerHtml]="appConfService.get().elements?.modal.icons[0].value | safeHtml"></i>
            </button>
        </div>
        <mat-accordion #accordion hideToggle>
            <mat-expansion-panel *ngFor="let expansion of modalData" expanded="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ expansion.title }}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ expansion.subTitle }}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let content of expansion.content">
                    <h5>{{ content.title }}</h5>
                    <i>{{ content.deviceName }}</i>
                    <ul>
                        <li *ngFor="let description of content.description">
                            <p>{{ description }}</p>
                        </li>
                    </ul>
                </ng-container>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>