import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MiniLoaderService {
  private _loader: boolean;
  public get loader(): boolean {
    return this._loader;
  }
  public set loader(value: boolean) {
    this._loader = value;
  }

  private _actionLoader: boolean;
  public get actionLoader(): boolean {
    return this._actionLoader;
  }
  public set actionLoader(value: boolean) {
    this._actionLoader = value;
  }

  private _title: string;
  public get title(): string {
    return this._title;
  }
  public set title(v: string) {
    this._title = v;
  }

  constructor() {
    this._loader = this._actionLoader = false;
    this._title = 'Loading...';
  }
}
