import { CompanyResponse } from './companies.model';
import { UserResponse } from './users.model';

export class NavigationBarItem {
  id?: number;
  name?: string;
  route?: string;
  urlParam?: string;
  icon?: string;
  expandable?: boolean;
  level?: number;
  activate?: boolean;
  children?: NavigationBarItem[];

  constructor(model: NavigationBarItem) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.route = model.route || '';
    this.urlParam = model.urlParam || '';
    this.icon = model.icon || '';
    this.expandable = model.expandable || false;
    this.level = model.level || 0;
    this.activate = model.activate || false;
    this.children = model.children || (null as any);
  }
}

export enum ModalType {
  Add = 0,
  Edit = 1,
}

export class UserDataModal {
  companies?: CompanyResponse[];
  user?: UserResponse;

  constructor(model: UserDataModal) {
    this.companies = model.companies || [];
    this.user = model.user || (null as any);
  }
}

//-----For version modal
export class AppVersionModal {
  title?: string;
  subTitle?: string;
  content?: AppVersionContent[];

  constructor(model: AppVersionModal) {
    this.title = model.title || '';
    this.subTitle = model.subTitle || '';
    this.content = model.content || (null as any);
  }
}

export class AppVersionContent {
  title?: string;
  deviceName?: string;
  description?: string[];

  constructor(model: AppVersionContent) {
    this.title = model.title || '';
    this.deviceName = model.deviceName || '';
    this.description = model.description || (null as any);
  }
}
//--------------------------

//--------For app configs
export class ElementValueModel {
  name?: string;
  value?: string;

  constructor(model: ElementValueModel) {
    this.name = model.name || '';
    this.value = model.value || '';
  }
}

export class ElementConfigModel {
  background?: string;
  icons?: ElementValueModel[];

  constructor(model: ElementConfigModel) {
    this.background = model.background || '';
    this.icons = model.icons || (null as any);
  }
}

export class TableConfigModel {
  header?: ElementConfigModel;
  body?: ElementConfigModel;

  constructor(model: TableConfigModel) {
    this.header = model.header || (null as any);
    this.body = model.body || (null as any);
  }
}
//--------------------------
