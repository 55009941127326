import { AppVersionModal } from './models/Application.model';

export const MODAL_DATA = new Array<AppVersionModal>(
  {
    title: 'V1.0.9',
    subTitle: 'Dec 22, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The button to delete a user in the user list was nonfunctional',
          "Adding a user with an email already in the database would display an infinite 'Loading' widget",
          'Safari Browser - Was unable to log out of Bay Manager',
        ],
      },
      {
        deviceName: 'FS Launcher Application',
        description: [
          'Game Info Pop up - Basketball had an incorrectly capitalized A.',
          'Game Info Pop up - Soccer had an incorrectly capitalized Or.',
          'Game Info Pop up - Hockey had incorrect capitalizations.',
          'Game Info Pop up - Rugby had overlapping words in the info pop up.',
          "Game Info Pop up - Zombie Dodgeball had a sentence that doesn't make sense.",
          'Launcher put out different Login errors.',
          'An application would open in the background and the user could not access it when clicking on any other game icon while the first game was loading',
          'Finalizing First Time Setup to the Commercial BM environment did not automatically open Overlay',
          'Locking Launcher during an active event would stop that event from affecting Launcher',
          'The Guide page text would remain on the screen when an event starts',
        ],
      },
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
          'Added Ping/Pong signal to Bay manager and DAL to keep connections active',
        ],
      },
    ],
  },
  {
    title: 'V1.0.8',
    subTitle: 'Dec 22, 2022',
    content: [
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
          'New UI modifications',
        ],
      },
    ],
  },
  {
    title: 'V1.0.7',
    subTitle: 'Oct 28, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The Simulator page did not properly load the first time the site is opened',
          "Pressing the 'Back' arrow while on the login page would display a black screen",
          "The user did not receive a notification when a Simulator's license becomes invalid",
          'Event end "Success" message would display the event name as \'undefined\'',
          'Refreshing the site would cause any assistance notifications to be removed',
        ],
      },
      {
        title: 'IMPROVEMENTS',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
        ],
      },
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: ['New UI modifications'],
      },
    ],
  },
  {
    title: 'V1.0.6',
    subTitle: 'Oct 10, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'Fixed issue where the user did not receive a notification when a Simulator’s license became invalid',
          'Fixed issue when session extension notifications could be approved after its event ends and would extend the ended event',
          'Fixed issue where extending a session through Bay Manager would not update the actual event time in Launcher',
          'Fixed issue when refreshing the site would cause any assistance notifications to be removed',
          'Fixed issue when clicking on notification from Microsoft Edge for session extension brings you to a page with an error.',
          'Fixed issue when assistance menu notification did not show up on Bay Manager website unless page was refreshed.',
          'Fixed issue when starting an event with the FSL unlocked canceled event on website but FSO still displayed event timer',
          'Fixed issue with (Safari/iOS) browsers. There was no functionality when tapping on a calendar date to create an event',
          'Fixed issue when editing date, start, or end times would not save until refreshing the page when the user edits them right after creating an event',
          'Fixed issue when clicking to edit an event in month view when using the "+X more" list widget would overlap with the \'Edit Event\' widget',
          'Fixed issue when controlling two simulators at once from Bay Manager website with the same login, connection was lost to both.',
          'Fixed issue when simulator showed as offline if left idle for too long.',
          'Fixed issue with (iPad) notifications for session extension did not show up when the notifications icon is selected.',
        ],
      },
      {
        title: 'IMPROVEMENTS',
        deviceName: 'FS Bay Manager Website',
        description: [
          'The extra session request notification was misaligned when viewed in the notifications area of the Sim page',
          'Force quit Launcher did not delete event.',
        ],
      },
      {
        title: 'NEW FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'User Page & User Table New Design',
          'Add API endpoint to save user’s pin code from launcher',
          'New UI modifications',
        ],
      },
    ],
  },
  {
    title: 'V1.0.1',
    subTitle: 'Aug 15, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'A duplicate session extension notification would be created when the user extends an active event and refreshes the page',
          'Session extension notifications would not automatically remove themselves from the Simulator page when its event ends',
          'The user was able to create conflicting events when refreshing the page after attempting to create a conflicting event',
          'Could create a new book in event as same time as existing event. Also didn’t update Launcher time as a result',
          'Sim timer desync when focused on different tabs and returning to Bay Manager',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0',
    subTitle: 'Aug 01, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Fixed issue where the "Bay Manager" logo could be seen behind the release notes when scrolling in the change log window.',
          'Fixed issue where the changelog window was briefly expanded when first opening it.',
          'Fixed issue where simulator session timer began with more time than the event was created with when an event started.',
          'Fixed issue where the user could scroll down past the Start button to the login section when loading the login page.',
          'Fixed issue where the previous location selected would not be remembered when refreshing the Simulator page.',
          'Fixed issue when you hover over the Month button and the List button it will showed "MONTH view" and "LIST view".',
          'Fixed issue with the Simulators page If the name of a Sim was too big it would overlap with the 3 dot menu.',
          'Fixed issue with Safari browser when extending a time of an event, the session end time did not show properly.',
          'Fixed issue with Safari browser for iPads were unable to unlock/lock a sim properly.',
          'Fixed issue where the user was prompted to reset their password when logging in with Admin Utility credentials.',
          'Fixed issue where the "Lock" option for a Sim was not capitalized.',
          'Fixed issue where user was able to select Lock and Unlock options for a Sim when it is offline.',
          'Fixed issue where error messages displayed inconsistently.',
          'Fixed issue where adding an event with the walk in button in simulators tab did not show up in the simid unless the page was refreshed.',
          'Fixed issue when deleting an event didn’t update the Launcher, Session Timer continued to count down.',
          'Fixed issue where event still ran on Bay Manager when launcher on Sim closed.',
          'Fixed issue with no error message would display for an incorrect user account.',
          'Fixed issue where countdown time in simulators tab should have leading 0 for single digits.',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'When a session ended FS Launcher did not close FS Golf.',
          'Multiple game instances of a game could be loaded when the user attempted to play a game multiple times',
          'Session did not start until the user relaunched FS Launcher when an active event started',
          'The "New End Time" of the "Session Extension" option was incorrect when using the "Assistance" menu',
          'User could not click on "Submit" button when trying to send a session extension request',
          'Starting Launcher, the user would briefly see the Games Library when the user is automatically logged in',
          'There is was no User Account menu tab logged into Commercial Bay Manager FSL',
          'The "?" icon that opens the Help & Documentation window was gone when reaching the login page of any FSL environment',
          'Multiple texts remained on guide menu',
          'When Showdown opened from Launcher a pop up saying the game is closed showed before the game actually opened',
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'Bay Manager Website',
        description: [
          'Added logic to cancel an event session on BM from launcher',
          'Updated Email Template look and feel',
          'On the Event List page, added columns with further details to improve visual feel',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: ['Assistance menu changes for user ease'],
      },
    ],
  },
  {
    title: 'V1.0.0-5',
    subTitle: 'Jul 12, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Clicking on a calendar date does not automatically select the date',
          'Error message has a spelling error when incorrectly resetting a password',
          'Newly created events are not showing up in the calendar or list view for the user to view or edit them',
          'Pressing "Enter" while in the "Name" input field during event creation will clear any input',
          'Safari Browser - The "Change Password" button could not be interacted with',
          'Safari Browser - Refreshing the page would log out the user',
          'User is able to login to Bay Manager using Admin Utility credentials',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Resolved issue were you were unable to click on the assistance menu items',
          'Resolved issue when you logged out of you user profile and re-open FS Launcher, the login window automatically logged you back into your account',
          'Resolved issue when selecting logout from the hidden admin menu did not redirect you back to the login page',
          'Resolved issue when launcher was not locking or unlocking through the Bay Manager website',
          'Resolved issue after first time setup the login menu didn’t appear',
          'Resolved issue were you could not submit an assistance request',
          'Resolved issue with remembered login resetting after a subsequent auto login',
          'Resolved issue in the Games Library where E6 Launching causes FS Launcher to hang',
          'Resolved issue in Games Library where Showdown Golf was not launching',
          'Resolved issue where uninstalled games display in their original position when viewed in the games library',
          'Resolved issue with E6 and Showdown would not display on certain simulators even when installed',
          'Resolved issue where there was no functionality to uncheck "Stay Logged In" when logging into FS Launcher Residential',
          'Resolved issue with uninstalled game icons were nearly blacked out entirely when viewed on a widescreen sim',
          'Resolved issue with certain installed games would not be registered as being installed',
          'Resolved issue with game icon display for 4:3 sims',
          'Games Library fixes',
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'FS Bay Manager Website',
        description: [
          'Deleting and canceling an event on FS Bay Manager website will Remove Session Timer in FS Launcher application',
          'Hide lock & unlock options in Simulators Management simulator menu when there is a session running',
          'Session extension and cancelling options during an active session',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Ability to cancel events once they have started - Subscribing to cancellation message',
          'Added a lockout button in admin menu',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-4',
    subTitle: 'Jun 21, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'New fonts on BM not showing on Staging.',
          'Sim Management tab - Session Extension - cannot extend active session at the last minute of time',
          'Pressing the `Back` arrow while on the login page will display a black screen',
          'An event that is just starting will not show underneath the respective simulator until the page is refreshed',
          'When creating an event drop down menu`s for date and times are nonfunctional - Safari browser',
          'The user is able to input `1` or `2` to set the Start or End time to AM or PM respectively when adding an event',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-3',
    subTitle: 'May 31, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Adjusted the layout of the simulators page on iPad',
          'Certain simulators would not show as online in the simulators tab after successfully authenticating in FS Launcher',
          'Safari Browser - Login video did not play before going to the login page',
          'Safari Browser - Horizontal view had large white borders on the left, right, and bottom of the page',
          'Safari Browser - The "Change Password" button could not be interacted with',
          'Safari Browser - Refreshing the page would log out the user',
          'Safari Browser - Was unable to log out of Bay Manager website',
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'Bay Manager Website',
        description: [
          'Simulators Management Tab - License renewal popup menu to renew sim license added',
          'Simulators Management Tab - Ability to extend active event time',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-2',
    subTitle: 'May 17, 2022',
    content: [
      {
        title: 'BUG FIXES',
        deviceName: 'Bay Manager Website',
        description: [
          'Start or end time shows as "Invalid DateTime" when attempting to create an event on mobile',
          'User cannot create events when manually entering a start or end time',
          'The "End Time" of an event will say "Invalid DateTime" when using the "+30 min" or "+1 hr" buttons in the "Book Event" page',
          'Certain simulators will not show as online in the simulators tab after successfully authenticating in Launcher',
          'Assistance notification does not reference the sim or event',
          'Safari Browser - Creating or editing events will not update',
          'Safari - browserAdding event does not work',
          'Notification for Sim going online or offline notification displays incorrect sim bay ',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Uninstalled games display in their original position when viewed in the games library',
          'E6 and Showdown will not display on certain simulators even when installed',
          'There is no functionality to uncheck "Stay Logged In" when logging into FS Launcher Residential',
          'Uninstalled game icons are nearly blacked out entirely when viewed on a widescreen sim',
          'Certain installed games will not be registered as being installed',
          'Game icons display issues for 4:3 sims',
          "When overlay app closes unexpectedly/manual during a launcher session, launcher doesn't return to a locked state",
        ],
      },
      {
        title: 'FEATURES',
        deviceName: 'Bay Manager Website',
        description: [
          'Ability to cancel active events from the Simulators Management tab',
          'iPad - Google Chrome support',
          'Booking clock colors adjustments',
          'Super User Accounts - ability to add additional user accounts',
          'Added a Walk In button on the Simulators page',
          'Added new video to welcome page',
          'Ability to manage sims with multiple user accounts',
        ],
      },
      {
        deviceName: 'FS Launcher',
        description: [
          'Games library - Only display games titles which are licensed ',
          'Added a lockout button option in the hidden admin menu',
          'Added standard login module for commercial operators',
          'Call to database for commercial user PIN for ability to manage hidden admin PIN number from Bay Manager website',
          'Home screen improvements to UI look and feel',
        ],
      },
    ],
  },
  {
    title: 'V1.0.0-1',
    subTitle: 'May 03, 2022',
    content: [
      {
        title: 'BUG FIXES',
        description: [
          '"Login Failed" spelling issue.',
          'Clicking to edit an event in month view when using the "+X more" list widget will overlap with the "Edit Event" widget',
          'User can add an event with a start date year of 5 digits or more',
        ],
      },
      {
        title: 'TASK',
        description: [
          'Add check inside DAL SignIn method for a passed user email against the companies if user email exists in a company',
          'Versioning section',
        ],
      },
    ],
  }
);

export const COMPANY_TAB_ID = '_fs_cmp_tab_id';
