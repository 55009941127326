import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationBarService {
  private _urlParam: Subject<string> = new Subject<string>();

  constructor() {}

  public setUrlParam(param: string): void {
    window.localStorage.setItem('_fs_nav_name', param);
    this._urlParam.next(param);
  }

  public removeUrlParam(): void {
    window.localStorage.removeItem('_fs_nav_name');
  }

  public getUrlParam(): Observable<string> {
    return this._urlParam;
  }
}
