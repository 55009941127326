export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const intialValueInterval = async (
  callbackValue: () => {},
  delay: number = 100
): Promise<any> => {
  while (typeof callbackValue() === 'undefined' || callbackValue() === null)
    await sleep(delay);

  return callbackValue();
};
