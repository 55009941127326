export const environment = {
  production: true,
  resourceServerURL: 'http://localhost:8081',
  apiServer: 'https://stglauncher.fullswingapps.com/api/fs',
  awsAPIURL: 'https://k0zmbfs553.execute-api.us-west-2.amazonaws.com/staging',
  hostName: 'stgbaymanageradmin.fullswingapps.com',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'event',
  //Local strorage values
  navName: '_fs_nav_name',
};
