import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private snackBar: MatSnackBar) {}

  public SortBy(by: string | any, arr: any[], sorted: boolean): boolean {
    arr.sort((a: any, b: any) => {
      if (a[by] < b[by]) {
        return sorted ? 1 : -1;
      }
      if (a[by] > b[by]) {
        return sorted ? -1 : 1;
      }
      return 0;
    });
    return !sorted;
  }

  public notify(msg: string, action: string, duration: number = 3000): void {
    this.snackBar.open(msg, action, {
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  public getHoursFromTimeFormat(time: string): number {
    if (time && time.length) {
      let hours = parseInt(time.split(':')[0]) ?? 0;
      return hours;
    }
    return 0;
  }

  public getMinutesFromTimeFormat(time: string): number {
    if (time && time.length) {
      let minutes = parseInt(time.split(':')[1]) ?? 0;
      return minutes;
    }
    return 0;
  }

  public getTime(date: Date): string {
    let h = new Date(date).getHours();
    let m = new Date(date).getMinutes();
    return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
  }

  public isValidDate(dateString: string | any): boolean {
    let reg = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    // First check for the pattern
    dateString = (dateString as String).replace(/-/g, '/');

    if (!reg.test(dateString)) {
      let numArr = (dateString as String).split('/'),
        newDate = [];
      for (let index = numArr.length - 1; index >= 0; index--)
        newDate.push(numArr[index]);
      dateString = newDate.toString().replace(/,/g, '/');
      if (!reg.test(dateString)) return false;
    }

    // Parse the date parts to integers
    var parts = dateString.split('/');
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  public onClear(event: any, control: FormControl): void {
    if (event && event.pointerType) {
      if (event.pointerType === 'mouse' || event.pointerType === 'touch') {
        control.reset();
      }
    } else {
      control.reset();
    }
  }

  public set24TimeFormat(time: string): string {
    if (time && time.length) {
      let timeFormat: string = time
        .toLowerCase()
        .substring(time.length, time.length - 2);

      if (!timeFormat || !timeFormat.length) return time;

      let fixedTime = '';
      let hour = parseInt(time.split(':')[0]),
        min = parseInt(time.split(':')[1]);

      if (timeFormat === 'pm') {
        time = time.toLowerCase().replace('pm', '').trim();
        switch (hour) {
          case 1:
            fixedTime += 13;
            break;
          case 2:
            fixedTime += 14;
            break;
          case 3:
            fixedTime += 15;
            break;
          case 4:
            fixedTime += 16;
            break;
          case 5:
            fixedTime += 17;
            break;
          case 6:
            fixedTime += 18;
            break;
          case 7:
            fixedTime += 19;
            break;
          case 8:
            fixedTime += 20;
            break;
          case 9:
            fixedTime += 21;
            break;
          case 10:
            fixedTime += 22;
            break;
          case 11:
            fixedTime += 23;
            break;
          case 12:
            fixedTime += 12;
            break;
          default:
            break;
        }

        fixedTime += ':' + min;
        return fixedTime;
      } else if (timeFormat === 'am') {
        time = time.toLowerCase().replace('am', '').trim();
        if (hour == 12) hour = 0;
        return hour + ':' + min;
      }
    }

    return time;
  }

  public formatDate(date: any): any {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  //Date format for mobile browser
  public formatDate2(dateInput: string | any): Date {
    if (dateInput && dateInput.length) {
      dateInput = (dateInput as String).replace(/\//g, '-');

      let dateWS = dateInput;
      let date = dateWS.split(' ')[0].split('-');
      let time = dateWS.split(' ')[1]?.split(':') ?? null;

      return new Date(
        +date[0],
        +date[1] - 1,
        +date[2],
        time[0] && time[0].length ? +time[0] : null,
        time[1] && time[1].length ? +time[1] : null
      );
    }
    return null;
  }

  public searchData(
    value: string,
    destArr: any[],
    searchOnProperty: string
  ): any[] {
    if (value && value.length) {
      let tmpArr = [];
      value = value.toUpperCase();
      for (let index = 0; index < destArr.length; index++) {
        let prop = destArr[index][searchOnProperty];
        let find = prop.toUpperCase().indexOf(value);
        if (find > -1) {
          tmpArr.unshift(destArr[index]);
        }
      }
      return tmpArr;
    } else {
      return destArr;
    }
  }

  public multiSearchData(
    value: string,
    destArr: any[],
    searchOnProperty: string[]
  ): any[] {
    if (value && value.length) {
      let tmpArr = [];
      value = value.toUpperCase();
      for (let index = 0; index < destArr.length; index++) {
        for (
          let propIndex = 0;
          propIndex < searchOnProperty.length;
          propIndex++
        ) {
          if (destArr[index][searchOnProperty[propIndex]]) {
            let prop = destArr[index][searchOnProperty[propIndex]];
            let find = prop.toUpperCase().indexOf(value);
            if (find > -1) {
              tmpArr.unshift(destArr[index]);
              break;
            }
          }
        }
      }
      return tmpArr;
    } else {
      return destArr;
    }
  }

  public capitalizeWord(value: string): string {
    value = value.trim();
    if (value && value.length) {
      let strArr = value.split(' ');
      for (let index = 0; index < strArr.length; index++) {
        let ch = strArr[index][0];
        strArr[index] =
          ch.toUpperCase() + strArr[index].substring(1, strArr[index].length);
      }
      return strArr.toString().replace(/,/g, ' ');
    }

    return '';
  }

  public expiringDateColor(value: Date): string {
    if (typeof value === 'undefined' || value === null) return '#000000';

    let expiringDate = new Date(value);
    let dateNow = new Date();
    if (dateNow > expiringDate) {
      return '#FF0000';
    } else {
      let after30Days = new Date();
      after30Days.setDate(dateNow.getDate() + 30);

      if (expiringDate > dateNow && expiringDate < after30Days) {
        return '#F2994A';
      } else {
        return '#FFFFFF';
      }
    }
  }

  public printRandomNumber(
    digits: number = 100,
    inNegRange: boolean = false
  ): number {
    let num = Math.round(Math.random() * digits);

    if (num === 0) return num;

    if (inNegRange) {
      let isPos = Math.round(Math.random() * 10) % 2 === 0;
      return isPos ? num : num * -1;
    }

    return num;
  }

  public strUpperFirst(source: string): string {
    return source
      ?.trim()
      ?.split(' ')
      ?.map((m) => m.replace(/^./, m[0].toUpperCase()))
      ?.join(' ');
  }
}
